import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { BaseService } from '../../shared/base-service';
import { StateCityModel } from '../models/city-state-model';



@Injectable({ providedIn: 'root' })
export class CountryStateService extends BaseService<StateCityModel> {
    private selectedStateSource = new BehaviorSubject<StateCityModel | null>(null);
    selectedStateSourceChanges$ = this.selectedStateSource.asObservable();

    private  localHttpClient: HttpClient;
    constructor(httpClient: HttpClient) {
        super( httpClient, 'get_state_on_country');
        this.localHttpClient = httpClient;
    }

    changeSelectedTenantType(selectedState: StateCityModel | null ): void {
        this.selectedStateSource.next(selectedState);
    }
}