import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { NotificationService } from '../../../shared/notification.service';
import { InvoiceDataSource } from '../../../invoices/data/invoice-data.source';
import { InvoiceService } from '../../../invoices/data/invoice.service';
import { PropertyService } from '../../data/property.service';
import { InvoiceModel } from '../../../invoices/models/invoice-model';
import { Table } from 'primeng/table';


@Component({
    selector: 'robi-property-invoice',
    templateUrl: './property-invoice.component.html',
    styleUrls: ['./property-invoice.component.css']
})
export class PropertyInvoiceComponent implements OnInit, AfterViewInit {
    invoiceColumns = [
        'invoice_number',
        'invoice_date',
        'invoice_amount',
        'amount_paid',
        'amount_due',
        'due_date',
        'status',
    ];

    // Data for the list table display
    invoiceDataSource: InvoiceDataSource;

    // pagination
    @ViewChild(MatPaginator, {static: true }) paginator: MatPaginator;

    // Pagination
    length: number;
    pageIndex = 0;
    pageSizeOptions: number[] = [5, 10, 25, 50, 100];
    meta: any;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    // Search field
    @ViewChild('search', {static: true}) search: ElementRef;

    loader = false;

    propertyData: any;
    propertyID: string;
    Invoice_all_data: any = []
    selectAll: boolean = false;
    first = 0;
    page: any = 0;
    rows = 5;
    search_data: any;
    alldata: any = [];
    httpOptions: any;
    loading: boolean;
    constructor(private notification: NotificationService,
                private propertyService: PropertyService,
                private invoiceService: InvoiceService) {}

    ngOnInit() {

    }

    load_Invoice_data(e?) {
        this.propertyService.selectedPropertyChanges$.subscribe(data => {
            if (data) {
                this.propertyData = data;
                this.propertyID = data.id;


                // if (e.first != 0) {

                //     this.page = parseInt(this.page) + 1;

                // } else {
                //     this.page = 1;

                // }

                if(e.first == 0){
                    this.page = 1
                }else{
                    this.page=(e.first/e.rows)+1;
                }
                
                this.loading = true;

                this.propertyService.getNestedNew(this.propertyID + "/invoices", '', this.page, e.rows, '', e.sortOrder == 1 ? 'desc' : 'asc', '', '').subscribe((res: any) => {
                    this.Invoice_all_data = res.data
                    this.alldata = res;
                    this.loading = false;


                }, (error) => {
                    this.loading = false;
                    console.log(JSON.stringify(error));
                    console.log("Oooops!")
                })

            }
        });
    }
    /**
     * Fetch data from data lead
     */
    loadData() {
        this.invoiceDataSource.loadNested(
            this.propertyService.nestedInvoicesUrl(this.propertyID),
            this.search.nativeElement.value,
            (this.paginator.pageIndex + 1),
            (this.paginator.pageSize),
            this.sort.active,
            this.sort.direction
        );
    }

    /**
     * Handle search and pagination
     */
    ngAfterViewInit() {
       
    }

    /**
     * Empty search box
     */
    clearSearch() {
        this.search.nativeElement.value = '';
        this.loadData()
    }

    onInvoiceSelected(invoice: InvoiceModel): void {
        this.invoiceService.changeSelectedInvoice(invoice);
    }

    clear(table: Table) {
        table.clear();
        this.search_data = '';
        this.rows = 5;
        this.page = 0;
    }
}
