import { Component, OnInit } from '@angular/core';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AddcityComponent } from './addcity/addcity.component';


import { LocalStorageService } from 'app/core/core.module';
import { AUTH_KEY } from '../../../app/authentication/auth.effects';
import { ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';
import { LazyLoadEvent } from 'primeng/api';
import { MessageService } from 'primeng/api';

import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { AddTenantComponent } from 'app/tenants/add/add-tenant.component';
import { CityService } from './data/city.service';
import { CityModel } from './model/city-model';
import { TranslateService } from '@ngx-translate/core';
import { UserSettingService } from '../user/data/user-setting.service';

@Component({
    selector: 'app-city',
    templateUrl: './city.component.html',
    styleUrls: ['./city.component.scss']

})
export class CityComponent implements OnInit {


    loader = false;


    // Pagination
    length: number;
    pageIndex = 0;
    pageSizeOptions: number[] = [5, 10, 25, 50, 100];
    meta: any;

    city_all_data: any = []
    selectAll: boolean = false;
    first = 0;
    page: any = 0;
    rows = 5;
    search_data: any;
    alldata: any = [];
    httpOptions: any;
    loading: boolean;

    totalRecords: any = [];
    selectedData: CityModel[];

    Id: any = [];
    activeUser: { userType: string; userID: string; };
    constructor(
        private cityService: CityService, public translate: TranslateService,private userService: UserSettingService,
        public ref: DynamicDialogRef,public dialogService: DialogService, private confirmationService: ConfirmationService,  public httpClient: HttpClient, public messageService: MessageService, public localStorageService: LocalStorageService) {
            this.activeUser = this.userService.getActiveUser();
        }


    ngOnInit() {
        // this.httpClient.get("https://quintonic.fr/api/events").subscribe(res => {
        //     console.log(res)
        // })
    }

    /**
   *  loading Data first
   */

    load_city_data(e?) {

        if(e.first == 0){
            this.page = 1
        }else{
            this.page=(e.first/e.rows)+1;
        }
        this.loading = true;

        this.cityService.getAll(e.globalFilter == null ? '' : e.globalFilter, this.page, e.rows, e.sortField == undefined ? 'updated_at' : e.sortField, e.sortOrder == 1 ? 'desc' : 'asc', '', '').subscribe((res: any) => {
            this.city_all_data = res.data
            this.alldata = res;
            this.loading = false;
            this.totalRecords = this.alldata.meta?.total;

        }, (error) => {
            this.loading = false;
            console.log(JSON.stringify(error));
            console.log("Oooops!")
        })
    }

    /**
    *  clear Data
    */

    clear(table: Table) {
        table.clear();
        this.search_data = '';
        this.rows = 5;
        this.page = 0;
    }


    /**
   *  Edit Add Data
   */


    edit_add_Dialog(data, isAdd) {
        this.selectedData = null;
        this.Id = [];
        this.translate.get('alert').subscribe(async (val) => {
            if (isAdd == true) {
                this.ref = this.dialogService.open(AddcityComponent, {
                    data: {
                        isAdd: true
                    },
                    header: val.AddCity,
                    width: 'auto'
                });
            } else if (isAdd == false) {
                this.ref = this.dialogService.open(AddcityComponent, {
                    data: {
                        id: data.id,
                        city_name: data.city_name,
                        city_description: data.city_description,
                        city_display_name: data.city_display_name,
                        city_display_name_fr: data.city_display_name_fr,
                        state_id: data.state_id,
                        country_id:data.country_id,
                        isAdd: false
                    },
                    header: val.EditCity,
                    width: 'auto'
                });
            }

            this.ref.onClose.subscribe((res) => {

                if (res == 'yes') {
                    this.loadData();
                }

            });
        });
    }


    /**
   * Fetch data from data lead
   */

    loadData() {
        this.loading = true;
        this.cityService.getAll('', 1, this.rows, 'updated_at', 'desc', '', '').subscribe((res: any) => {
            this.city_all_data = res.data
            this.alldata = res;
            this.loading = false;
            this.totalRecords = this.alldata.meta?.total;
        }, (error) => {
            this.loading = false;
            console.log(JSON.stringify(error));
            console.log("Oooops!")
        })

    }


    ngAfterViewInit() {

    }

    /**
     * Empty search box
     */
    clearSearch() {
        // this.search.nativeElement.value = '';
        this.loadData()
    }


    /**
     * Open Edit form
     * @param cityType
     */

    openConfirmationDialog(CityTypeData, index) {
        this.translate.get('alert').subscribe(async (val) => {
            this.confirmationService.confirm({
                message: val.message + " " + CityTypeData.city_display_name + '?',
                header: val.header,
                icon: 'pi pi-exclamation-triangle',
                key: 'confirmWeightTest',
                accept: () => {
                    this.delete(CityTypeData, index);
                }
            });
        });

    }

    /**
     * Remove resource from db
     * @param cityType
     */

    delete(cityType: CityModel, index) {
        this.loader = true;
        this.cityService.delete(cityType)
            .subscribe((data) => {
                this.loader = false;
                this.city_all_data.splice(index, 1);
                this.translate.get('alert').subscribe(async (val) => {
                    this.messageService.add({ severity: 'success', summary: val.Success, detail: val.delete_city });
                });

            },
                (error) => {
                    this.loader = false;
                    if (error.error['message']) {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error['message'] });
                    } else {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Delete Error !!' });
                    }
                });
    }

    onSelectionChange(value = []) {
        console.log("value", value)
        this.selectAll = value.length === this.totalRecords;
        this.selectedData = value;
    }

    onSelectAllChange(event) {
        console.log("event", event)
        const checked = event.checked;

        if (checked) {

            this.selectedData = this.city_all_data;
            this.selectAll = true;

        }
        else {
            this.selectedData = [];
            this.selectAll = false;
        }
    }

    deleteSelectedProducts() {
        this.translate.get('alert').subscribe(async (val) => {
            this.confirmationService.confirm({
                message: val.message_new + " " + val.city + '?',
                header: val.header,
                icon: 'pi pi-exclamation-triangle',
                key: 'confirmWeightTest',
                accept: () => {
                    this.AllSelectedDeleted();
                }
            });

        });
    }

    AllSelectedDeleted() {
        let myItem = this.selectedData.map((item) => {
            this.Id.push({ id: item.id });
        });
        let body = {
            ids_data: this.Id
        }
        this.cityService.deleteSelectedData(body, "/all_city_destroy").subscribe(res => {
            console.log(res);
            this.loader = false;
            this.loadData();
            this.selectedData = null;
            this.Id = [];
            this.translate.get('alert').subscribe(async (val) => {
                this.messageService.add({ severity: 'success', summary: val.Success, detail: val.delete_city });
            });
        },
            (error) => {
                this.selectedData = null;
                this.Id = [];
                this.loader = false;
                if (error.error['message']) {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error['message'] });
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Delete Error !! ' });
                }
            });
    }
}
