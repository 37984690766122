import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { selectEffectiveTheme } from '../../core/settings/settings.selectors';
import { AuthenticationActions } from '../action-types';
import { selectorIsLoggedIn, selectorScopes } from '../auth.selectors';
import { selectorCompanyName, selectorUserScopes } from '../authentication.selectors';
import { MessageService } from 'primeng/api';
import { LocalStorageService } from 'app/core/core.module';
import { TranslateService } from '@ngx-translate/core';

@Component({ templateUrl: 'login.component.html', providers: [MessageService] , styleUrls: ['login.component.scss'] })
export class LoginComponent implements OnInit {

    loginForm: UntypedFormGroup;
    returnUrl: string;
    loginError = '';
    loader = false;
    token$: any;

    theme$: Observable<string>;

    loginScopes: any;
    companyName: string;
    constructor(private fb: UntypedFormBuilder,public translate:TranslateService , public localStorageService:LocalStorageService , private store: Store, private route: ActivatedRoute,
        private router: Router, private authenticationService: AuthenticationService,public messageService: MessageService) {
            let emailRegex =
            '^[a-zA-Z0-9_+&*-]+(?:\\.' +
            '[a-zA-Z0-9_+&*-]+)*@' +
            '(?:[a-zA-Z0-9-]+\\.)+[a-z' +
            'A-Z]{2,7}$';
        this.loginForm = fb.group({
            email: ['', [Validators.required, Validators.email,  Validators.pattern(emailRegex),]],
            password: ['', Validators.required]
        });
        this.store.pipe(select(selectorCompanyName)).subscribe(name => this.companyName = name);
      
    }

    Alphabet(event) {
        var charCode = (event.which) ? event.which : event.keyCode;
      
         if (!(charCode != 32)) {
            event.preventDefault();
            return false;
        }
    }
    ngOnInit() {
        this.theme$ = this.store.pipe(select(selectEffectiveTheme));

        this.store.dispatch(AuthenticationActions.actionLogout());
        this.returnUrl = '/';
    }

    /**
     * Fetch email field
     */
    get email() {
        return this.loginForm.get('email');
    }

    /**
     * Fetch password field
     */
    get password() {
        return this.loginForm.get('password');
    }

    /**
     * Login user against api
     */
    login() {
        this.loginForm.get('email').markAsTouched();
        this.loginForm.get('password').markAsTouched();
        if (this.loginForm.valid) {
            this.loginError = '';
            this.loader = true;

            this.authenticationService.login((this.email.value).trim(), this.password.value)
                .pipe(tap(
                    user => {
                        this.loader = false;
                        //   console.log('AuthActions.actionLogin({user}');
                          console.log(user);
                          
                         this.localStorageService.setItem("end_date",user.end_date);
                         this.translate.get('alert').subscribe(async (val) => {
                            this.messageService.add({ severity: 'success', summary: val.Success, detail: val.loginsuccess });
                        });
                        
                        this.store.dispatch(AuthenticationActions.actionLogin({ user }));

                        this.store.pipe(select(selectorUserScopes)).subscribe(scopes => {
                            this.loginScopes = scopes;
                            // We have a landlord
                            if (scopes?.find(x => x === 'am-landlord')) {
                                this.returnUrl = '/landlord/dashboard';
                            }
                            // We have a tenant
                            if (scopes?.find(x => x === 'am-tenant')) {
                                this.returnUrl = '/tenant/dashboard';
                            } else {
                                //
                            }
                        });

                        this.router.navigate([this.returnUrl]);
                    }
                ))
                .subscribe(
                    () => { },
                    (error) => {
                        // console.log(error);
                        if (error.error.message) {
                            this.loginError = error.error.message;
                            this.messageService.add({ severity: 'error', summary: 'Error', detail: this.loginError});
                        } else {

                            this.loginError = 'Server Error. Please try again later.';
                            this.messageService.add({ severity: 'error', summary: 'Error', detail: this.loginError});
                        }
                        this.loader = false;
                    });

        }
    }
}
