import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { ConfirmationDialogComponent } from './shared/delete/confirmation-dialog-component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {
  DefaultDataServiceConfig,
  EntityDataModule,
  HttpUrlGenerator,
} from '@ngrx/data';
import { JwtInterceptor } from './core/jwt.interceptor';
import { AppHttpUrlGenerator } from './core/app-http-url-generator';
import { ErrorInterceptor } from './core/error-handler/error.interceptor';
import { AuthenticationModule } from './authentication/authentication.module';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { StatementComponent } from './accounting/statement/statement.component';
import { PdfStatementComponent } from './accounting/pdf-statement/pdf-statement.component';
import {DropdownModule} from 'primeng/dropdown';
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from '@angular/common';
import { DialogService } from 'primeng/dynamicdialog';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { AppMainComponent } from './app.main.component';
import { AppRightPanelComponent } from './app.rightpanel.component';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { AppConfigComponent } from './app.config.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';
import { MenuService } from './app.menu.service';
import { ProductService } from './settings/property/productservice';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { SettingModule } from './settings/setting.module';
import { ToastModule } from 'primeng/toast';
import { PropertyModule } from './properties/property.module';
import {PanelModule} from 'primeng/panel';
import { CityModule } from './settings/city/city.module';
import {TableModule} from 'primeng/table';
import {RadioButtonModule} from 'primeng/radiobutton';
import { CountryModule } from './settings/country/country.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { SubscriptionComponent } from './subscription/subscription.component';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr');

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const defaultDataServiceConfig: DefaultDataServiceConfig = {
  root: 'https://www.tadaimmo.fr/tada_api/api/v1',
  timeout: 1000 * 60,
};

@NgModule({
  imports: [
    ToolbarModule,
    ButtonModule,
    SplitButtonModule,
    BrowserAnimationsModule,
    SharedModule,
    CoreModule,
    ComponentsModule,
    BrowserModule,
    CommonModule,
    RouterModule,
    AppRoutingModule,
    AuthenticationModule,
    SettingModule,
    NgxSpinnerModule,
    PropertyModule,
    PanelModule,
    CityModule,
    CountryModule,
    TableModule,
    DropdownModule,
    RadioButtonModule,
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EntityDataModule.forRoot({})
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    ConfirmationDialogComponent,
    StatementComponent,
    PdfStatementComponent,
    AppMainComponent,
    AppRightPanelComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    AppConfigComponent,
    AppTopBarComponent,
    AppFooterComponent

  ],
  providers: [
    MenuService,
    DialogService,
    // MessageService,
    ConfirmationService,
    PrimeNGConfig,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HttpUrlGenerator, useClass: AppHttpUrlGenerator },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig },
    ProductService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
  }
}
