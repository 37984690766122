import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from "@angular/platform-browser";
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SharedModule } from '../shared/shared.module';

import { FormsModule } from '@angular/forms';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    ToolbarModule,
    ButtonModule,
    SplitButtonModule
    
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent
    
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent
   
  ],
  providers:[

  ]
})
export class ComponentsModule { }
