import { Component, OnInit } from '@angular/core';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

import { LocalStorageService } from 'app/core/core.module';
import { AUTH_KEY } from '../../../app/authentication/auth.effects';
import { ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';
import { LazyLoadEvent } from 'primeng/api';
import { MessageService } from 'primeng/api';

import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';


import { TranslateService } from '@ngx-translate/core';
import { CountryModel } from './model/country.model';
import { CountryService } from './data/country.service';
import { AddCountryComponent } from './add-country/add-country.component';
import { UserSettingService } from '../user/data/user-setting.service';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})
export class CountryComponent implements OnInit {

  loader = false;


  // Pagination
  length: number;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  meta: any;

  Tenant_all_data: any = []
  selectAll: boolean = false;
  first = 0;
  page: any = 0;
  rows = 5;
  search_data: any;
  alldata: any = [];
  httpOptions: any;
  loading: boolean;

  totalRecords: any = [];
  selectedData: CountryModel[];

  Id:any=[];
    activeUser: any;

  constructor(
      private countryService: CountryService,public translate:TranslateService ,private userService: UserSettingService,
      public ref: DynamicDialogRef, private confirmationService: ConfirmationService, public dialogService: DialogService, public httpClient: HttpClient, public messageService: MessageService, public localStorageService: LocalStorageService) {
        this.activeUser = this.userService.getActiveUser();
    }


  ngOnInit() {

  }

  /**
 *  loading Data first
 */

  load_tenant_data(e?) {

    if(e.first == 0){
        this.page = 1
    }else{
        this.page=(e.first/e.rows)+1;
    }

      this.loading = true;

      this.countryService.getAll(e.globalFilter == null ? '' : e.globalFilter, this.page, e.rows, e.sortField == undefined ? 'updated_at' : e.sortField, e.sortOrder == 1 ? 'desc' : 'asc', '', '').subscribe((res: any) => {
          this.Tenant_all_data = res.data
          this.alldata = res;
          this.totalRecords = this.alldata.meta?.total;
          this.loading = false;


      }, (error) => {
          this.loading = false;
          console.log(JSON.stringify(error));
          console.log("Oooops!")
      })

  }

  /**
  *  clear Data
  */

  clear(table: Table) {
      table.clear();
      this.search_data = '';
      this.rows = 5;
      this.page = 0;
  }


  /**
 *  Edit Add Data
 */


  edit_add_Dialog(data, isAdd) {
      this.selectedData = null;
      this.Id = [];
      this.translate.get('alert').subscribe(async (val) => {
          if (isAdd == true) {
              this.ref = this.dialogService.open(AddCountryComponent, {
                  data: {
                      isAdd: true
                  },
                  header: val.Addcountry,
                  width: 'auto'
              });
          } else if (isAdd == false) {
              this.ref = this.dialogService.open(AddCountryComponent, {
                  data: {
                      id: data.id,
                      country_name: data.country_display_name,
                      country_description: data.country_description,
                      country_display_name: data.country_display_name,
                      country_display_name_fr: data.country_display_name_fr,
                      country_code: data.country_code,
                      isAdd: false
                  },
                  header: val.Addcountry,
                  width: 'auto'
              });
          }

          this.ref.onClose.subscribe((res) => {

              if (res == 'yes') {
                  this.loadData();
              }

          });
      });
  }


  /**
 * Fetch data from data lead
 */

  loadData() {
      this.loading = true;

      this.countryService.getAll('', 1, this.rows, 'updated_at', 'desc', '', '').subscribe((res: any) => {
          this.Tenant_all_data = res.data
          this.alldata = res;
          this.totalRecords = this.alldata.meta?.total;
          this.loading = false;
      }, error => {
          this.loading = false;
          console.log(JSON.stringify(error));
          console.log("Oooops!")
      });

  }


  ngAfterViewInit() {

  }

  /**
   * Empty search box
   */
  clearSearch() {
      // this.search.nativeElement.value = '';
      this.loadData()
  }


  /**
   * Open Edit form
   * @param emergencyType
   */

  openConfirmationDialog(emergencyTypeData,index) {
      this.translate.get('alert').subscribe(async (val) => {
          this.confirmationService.confirm({
              message: val.message + " " + emergencyTypeData.country_display_name + '?',
              header: val.header,
              icon: 'pi pi-exclamation-triangle',
              key: 'confirmWeightTest',
              accept: () => {
                  this.delete(emergencyTypeData,index);
              }
          });
      });

  }

  /**
   * Remove resource from db
   * @param emergencyType
   */

  delete(emergencyType: CountryModel,index) {
      this.loader = true;
      this.countryService.delete(emergencyType)
          .subscribe((data) => {
              this.loader = false;
              this.Tenant_all_data.splice(index, 1);

              this.translate.get('alert').subscribe(async (val) => {
                  this.messageService.add({ severity: 'success', summary: val.Success, detail: val.delete_country });
              });

          },
              (error) => {
                  this.loader = false;
                  if (error.error['message']) {
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error['message'] });
                  } else {
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Delete Error !!' });
                  }
              });
  }

  onSelectionChange(value = []) {
      console.log("value", value)
      this.selectAll = value.length === this.totalRecords;
      this.selectedData = value;
  }

  onSelectAllChange(event) {
      console.log("event", event)
      const checked = event.checked;

      if (checked) {

          this.selectedData = this.Tenant_all_data;
          this.selectAll = true;

      }
      else {
          this.selectedData = [];
          this.selectAll = false;
      }
  }

  deleteSelectedProducts() {
      this.translate.get('alert').subscribe(async (val) => {
          this.confirmationService.confirm({
              message: val.message_new + " " + val.country + '?',
              header: val.header,
              icon: 'pi pi-exclamation-triangle',
              key: 'confirmWeightTest',
              accept: () => {
                  this.AllSelectedDeleted();
              }
          });

      });
  }

  AllSelectedDeleted() {

      let myItem = this.selectedData.map((item) => {
          this.Id.push({ id: item.id });
      });
      let body = {
          ids_data: this.Id
      }
      this.countryService.deleteSelectedData(body, "/all_country_destroy").subscribe(res => {
          console.log(res);
          this.loader = false;
          this.loadData();
          this.Id=[];
          this.selectedData = null;
          this.translate.get('alert').subscribe(async (val) => {
              this.messageService.add({ severity: 'success', summary: val.Success, detail: val.delete_country });
          });
      },
          (error) => {
            this.selectedData = null;
            this.Id = [];
              this.loader = false;
              if (error.error['message']) {
                  this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error['message'] });
              } else {
                  this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Delete Error !! ' });
              }
          });
  }
}
