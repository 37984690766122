import { NgModule } from '@angular/core';
import { PropertyRoutingModule } from './property-routing.module';
import { PropertyComponent } from './property.component';
import { AddPropertyComponent } from './add/add-property.component';
import { SharedModule } from '../shared/shared.module';
import { ViewPropertyGeneralComponent } from './view/general/view-property-general.component';
import { ViewPropertyComponent } from './view/view-property.component';
import { PropertyUnitDetailsComponent } from './add/unit-details/property-unit-details.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartistModule } from 'ng-chartist';
import { PropertyLeaseComponent } from './view/lease/property-lease.component';
import { PropertyInvoiceComponent } from './view/invoice/property-invoice.component';
import { PropertyNoticeComponent } from './view/notice/property-notice.component';
import { PropertyUnitComponent } from './view/unit/property-unit.component';
import { StepsModule } from 'primeng/steps';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { MessageModule } from 'primeng/message';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToolbarModule } from 'primeng/toolbar';
import { PanelModule } from 'primeng/panel';
import { DialogModule } from 'primeng/dialog';
import { CardModule } from 'primeng/card';
import { TabMenuModule } from 'primeng/tabmenu';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NgxSpinnerModule } from "ngx-spinner";
import {TooltipModule} from 'primeng/tooltip';
@NgModule({
    imports: [
        SharedModule,
        PropertyRoutingModule,
        NgxMatSelectSearchModule,
        //  NgxChartsModule,
        // ChartistModule,
        StepsModule,
        ToastModule,
        DropdownModule,
        InputTextModule,
        CalendarModule,
        MessageModule,
        CheckboxModule,
        TableModule,
        ConfirmDialogModule,
        ToolbarModule,
        PanelModule,
        DialogModule,
        CardModule,
        TabMenuModule,
        InputSwitchModule,
        NgxSpinnerModule,
        TooltipModule
    ],
    declarations: [
        PropertyComponent,
        AddPropertyComponent,
        ViewPropertyGeneralComponent,
        ViewPropertyComponent,
        PropertyUnitDetailsComponent,
        PropertyLeaseComponent,
        PropertyInvoiceComponent,
        PropertyNoticeComponent,
        PropertyUnitComponent
    ], providers: [
        MessageService,
        ConfirmationService
    ]
})

export class PropertyModule {

    constructor() {
    }
}
