import { Routes, RouterModule } from '@angular/router';
import { SettingComponent } from './setting.component';
import { GeneralSettingResolverService } from './general/data/general-setting-resolver.service';
import { LeaseGeneralSettingResolverService } from './lease/general/data/lease-general-setting-resolver.service';
import { TenantGeneralSettingResolverService } from './tenant/general/data/tenant-general-setting-resolver.service';
import { PropertyGeneralSettingResolverService } from './property/general/data/property-general-setting-resolver.service';
export const ROUTES: Routes = [
    {
        path: '',
        component: SettingComponent,
        children: [
            {
                path: 'general-setting',
                loadChildren: () => import('app/settings/general/general-setting.module').then(m => m.GeneralSettingModule),
                resolve : { setting: GeneralSettingResolverService}
            },
            {
                path: 'property',
                loadChildren: () => import('app/settings/property/property-setting.module')
                    .then(m => m.PropertySettingModule),
                resolve : { leaseSetting: PropertyGeneralSettingResolverService}
            },
            {
                path: 'lease',
                loadChildren: () => import('app/settings/lease/lease-setting.module')
                    .then(m => m.LeaseSettingModule),
                resolve : { leaseSetting: LeaseGeneralSettingResolverService}
            },
            {
                path: 'tenant',
                loadChildren: () => import('app/settings/tenant/tenant-setting.module')
                    .then(m => m.TenantSettingModule),
                resolve : { tenantSetting: TenantGeneralSettingResolverService}
            },
            {
                path: 'email',
                loadChildren: () => import('app/settings/email/email-setting.module')
                    .then(m => m.EmailSettingModule)
            },
            {
                path: 'payments',
                loadChildren: () => import('app/settings/payment/payment-setting.module')
                    .then(m => m.PaymentSettingModule)
            },
            {
                path: 'user',
                loadChildren: () => import('app/settings/user/user-setting.module').then(m => m.UserSettingModule)
            },
            {
                path: 'city',
                loadChildren: () => import('app/settings/city/city.module').then(m => m.CityModule)
            },
            {
                path: 'emergency',
                loadChildren: () => import('app/settings/emergency/emergency.module').then(m => m.EmergencyModule)
            },
            {
                path: 'idproof',
                loadChildren: () => import('app/settings/id-proof/id-proof.module').then(m => m.IDProofModule)
            },
            {
                path: 'employment',
                loadChildren: () => import('app/settings/employment/employment.module').then(m => m.EmploymentModule)
            },
            {
                path: 'extracharges1',
                loadChildren: () => import('app/settings/extra-charges/extra-charges.module').then(m => m.ExtraChargesModule)
            },
            {
                path: 'kin-relation',
                loadChildren: () => import('app/settings/kin-relation/kin-relation.module').then(m => m.KinRelationModule)
            },
            {
                path: 'state',
                loadChildren: () => import('app/settings/state/state.module').then(m => m.StateModule)
            },
            {
                path: 'country',
                loadChildren: () => import('app/settings/country/country.module').then(m => m.CountryModule)
            }
        ]
    }
];

export const SettingRoutingModule = RouterModule.forChild(ROUTES);
