import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { UserSettingService } from '../../settings/user/data/user-setting.service';
import { UserSettingModel } from '../../settings/user/model/user-setting.model';
import { NavigationExtras, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({ templateUrl: 'forgot-password.component.html', providers: [MessageService], styleUrls: ['forgot-password.component.scss'] })
export class ForgotPasswordComponent implements OnInit {

    form: UntypedFormGroup;
    formErrors: any;
    loader = false;

    user: UserSettingModel;
    email:any
    constructor(private fb: UntypedFormBuilder, private userService: UserSettingService,
        private router: Router, public messageService: MessageService,public translate:TranslateService) {
        let emailRegex =
            '^[a-zA-Z0-9_+&*-]+(?:\\.' +
            '[a-zA-Z0-9_+&*-]+)*@' +
            '(?:[a-zA-Z0-9-]+\\.)+[a-z' +
            'A-Z]{2,8}' + '[\ ]{0,50}$';
        this.form = this.fb.group({
            email: ['', [Validators.required, Validators.pattern(emailRegex),
            Validators.minLength(3)]]
        });
    }

    /**
     *
     */
    ngOnInit(): void { }

    /**
     *
     */
    requestPassword() {

        this.form.get('email').markAsTouched();
        if (this.form.valid) {
            const body = Object.assign({}, this.user, this.form.value);
            body.email=this.email.trim()
            this.loader = true;
            this.userService.forgotPassword(body)
                .subscribe((data) => {
                    this.loader = false;
                    const param: NavigationExtras = {
                        queryParams: {
                          email:  this.email
                        }
                      }
                    // notify success
                    this.translate.get('alert').subscribe(async (val) => {
                        this.messageService.add({ severity: 'success', summary: val.Success, detail: val.checkemail });
                    });
                    
                    setTimeout(() => {this.router.navigate(['reset-password'],param);},3000);
                },
                    (error) => {
                        this.loader = false;

                        if (error.payment === 0) {
                            // notify error
                            return;
                        }
                        // An array of all form errors as returned by server
                        this.formErrors = error;

                        if (this.formErrors) {
                            // loop through from fields, If has an error, mark as invalid so mat-error can show
                            for (const prop in this.formErrors) {
                                if (this.form) {
                                    this.form.controls[prop].setErrors({ incorrect: true });
                                }
                            }
                        }
                    });
        }
    }

    Alphabet(event) {
        var charCode = (event.which) ? event.which : event.keyCode;
      
         if (!(charCode != 32)) {
            event.preventDefault();
            return false;
        }
    }
}
