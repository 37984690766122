import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { DynamicDialogRef, DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { LazyLoadEvent } from 'primeng/api';
import { CityModel } from '../model/city-model';
import { CityService } from '../data/city.service';
import { StateService } from 'app/settings/state/data/state.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";
import { CountryService } from 'app/settings/country/data/country.service';
import { StateCityModel } from 'app/landlords/models/city-state-model';
import { CountryStateService } from 'app/landlords/data/state-country.service';
@Component({
    selector: 'app-addcity',
    templateUrl: './addcity.component.html',
    styleUrls: []
})
export class AddcityComponent implements OnInit {

    form: UntypedFormGroup;

    formErrors: any;

    private errorInForm = new BehaviorSubject<boolean>(false);
    formError$ = this.errorInForm.asObservable();

    loader = false;

    formGroup: UntypedFormGroup;

    isAdd: boolean;
    cityType: CityModel;

    cityname: any;

    stateData$: any = [];

    countryData$: any = [];

    constructor(public country_service: CountryService, public CountryStateService: CountryStateService, public stateCityModel: StateCityModel, private spinner: NgxSpinnerService, public StateService: StateService, private messageService: MessageService, private cityService: CityService, private primengConfig: PrimeNGConfig, private fb: UntypedFormBuilder, public config: DynamicDialogConfig, public ref: DynamicDialogRef, public translate: TranslateService) {
        this.country_service.getAll('', 0, 300, '', 'asc', '', '').subscribe((res: any) => {
            // this.StateService.getAll('', 0, 4000, '', 'asc', '', '').subscribe((res: any) => {
            //     console.log(res.data);
            //     this.stateData$ = res.data
            this.countryData$ = res.data

            this.primengConfig.ripple = true;
            console.log(this.config.data);

            if (this.config.data.isAdd == true) {
                this.form = this.fb.group({
                    city_name: [''],
                    city_display_name: ['', [Validators.required]],
                    city_description: [''],
                    city_display_name_fr: ['', [Validators.required]],
                    state_id: ['', [Validators.required]],
                    country_id: ['', [Validators.required]],
                });
            } else if (this.config.data.isAdd == false) {
                this.form = this.fb.group({
                    city_name: [this.config.data.city_name],
                    city_display_name: [this.config.data.city_display_name, [Validators.required]],
                    city_description: [this.config.data.city_description],
                    id: [this.config.data.id],
                    city_display_name_fr: [this.config.data.city_display_name_fr, [Validators.required]],
                    state_id: [this.config.data.state_id, [Validators.required]],
                    country_id: [this.config.data.country_id, [Validators.required]],

                });
            }
            // });


            this.onChangeCountry(this.config.data.country_id)

        });
    }
    ngOnInit() {
      
    }


    onChangeCountry(event) {
        // if (this.update_data == "yes") {
        //     this.spinner.show();
        //     this.form.patchValue({
        //         state: '',
        //         city: ''
        //     });
        //     this.StateDtata$ = []
        //     this.CityDtata$ = [];
        //     //console.log(event)
            this.stateCityModel.state_id = ''
            this.stateCityModel.lang = "fr";
            this.stateCityModel.country_id = event;

        this.CountryStateService.getStateData(this.stateCityModel).subscribe((res: any) => {
            this.spinner.hide();
            if (res.success == true) {
                this.stateData$ = res.data
                //console.log("res", res);
            }

        })
        // }

    }


    /**
     * Create member
     */
    create() {
        let value = this.form.value.city_display_name + this.form.value.city_display_name_fr;
        this.form.patchValue({ city_name: value });
        this.form.get('city_display_name').markAsTouched();
        this.form.get('city_display_name_fr').markAsTouched();
        this.form.get('state_id').markAsTouched();
        this.form.get('country_id').markAsTouched();
        if (this.form.valid) {
            this.spinner.show();

            this.errorInForm.next(false);

            const body = Object.assign({}, this.cityType, this.form.value);

            this.loader = true;

            this.cityService.create(body).subscribe((data) => {
                this.onSaveComplete();
                this.spinner.hide();
                this.translate.get('alert').subscribe(async (val) => {
                    this.messageService.add({ severity: 'success', summary: val.Success, detail: val.city_created });
                });


            },
                (error) => {
                    this.spinner.hide();
                    this.loader = false;
                    this.errorInForm.next(true);
                    this.formErrors = error;
                    if (this.formErrors) {
                        for (const prop in this.formErrors) {
                            if (this.form) {
                                this.form.controls[prop]?.markAsTouched();
                                this.form.controls[prop]?.setErrors({ incorrect: true });
                            }
                        }
                    }
                    if (error.city_display_name[0]) {
                        this.translate.get('alert').subscribe(async (val) => {
                            this.messageService.add({ severity: 'error', summary: val.Error, detail: val.city_display_name });
                        });
                    }
                    if (error.city_display_name_fr[0]) {
                        this.translate.get('alert').subscribe(async (val) => {
                            this.messageService.add({ severity: 'error', summary: val.Error, detail: val.city_display_name_fr });
                        });
                    }
                });
        }
    }

    /**
     *
     */
    update() {
        let value = this.form.value.city_display_name + this.form.value.city_display_name_fr;
        this.form.patchValue({ city_name: value });
        this.form.get('city_display_name').markAsTouched();
        this.form.get('city_display_name_fr').markAsTouched();
        this.form.get('state_id').markAsTouched();
        this.form.get('country_id').markAsTouched();
        if (this.form.valid) {

            const body = Object.assign({}, this.cityType, this.form.value);

            this.loader = true;
            this.errorInForm.next(false);

            this.cityService.update(body).subscribe((data) => {
                this.loader = false;

                this.ref.close('yes');

                // notify success

                this.translate.get('alert').subscribe(async (val) => {
                    this.messageService.add({ severity: 'success', summary: val.Success, detail: val.city_updated });
                });

            },
                (error) => {
                    this.loader = false;
                    this.errorInForm.next(true);
                    this.formErrors = error;
                    if (this.formErrors) {
                        for (const prop in this.formErrors) {
                            if (this.form) {
                                this.form.controls[prop]?.markAsTouched();
                                this.form.controls[prop]?.setErrors({ incorrect: true });
                            }
                        }
                    }
                    if (error.city_display_name[0]) {
                        this.translate.get('alert').subscribe(async (val) => {
                            this.messageService.add({ severity: 'error', summary: val.Error, detail: val.city_display_name });
                        });
                    }
                    if (error.city_display_name_fr[0]) {
                        this.translate.get('alert').subscribe(async (val) => {
                            this.messageService.add({ severity: 'error', summary: val.Error, detail: val.city_display_name_fr });
                        });
                    }
                });
        }
    }

    /**
     * Create or Update Data
     */
    createOrUpdate() {
        this.isAdd ? this.create() : this.update();
    }

    close() {
        this.ref.close();
    }

    /**
     *
     */
    public onSaveComplete(): void {
        this.loader = false;
        this.form.reset();
        this.ref.close('yes');
    }

}

