import { Component } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AuthenticationService } from './authentication/authentication.service';
import { AuthActions } from './authentication/action-types';
import { tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectorUserFirstAndLastNames } from './authentication/authentication.selectors';
import { AppState } from './reducers';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from './core/core.module';
import { UserSettingService } from './settings/user/data/user-setting.service';
@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {
  loading: boolean;
  userNames$: any;
  langType: any = [{
    name: "English",
    id: "en"
  },
  {
    name: "French",
    id: "fr"
  }];
  language: any;
  activeUser: any;
  constructor(public localStorageService: LocalStorageService, private store: Store<AppState>, public translate: TranslateService, public app: AppMainComponent, private auth: AuthenticationService, private userService: UserSettingService) {
    this.userNames$ = this.store.pipe(select(selectorUserFirstAndLastNames));
    const language = this.localStorageService.getItem("lang");
    this.activeUser = this.userService.getActiveUser();
    if (language == "en") {
      this.language = "en";
    } else {
      this.language = "fr";
    }
  }

  logout() {
    console.log("logout... ...")
    this.loading = true;
    this.auth.logout()
      .pipe(
        tap(
          user => {
            this.loading = false;
           
            // const language = this.localStorageService.getItem("lang");
            // this.translate.setDefaultLang('en');
            // this.translate.use('en');
            this.store.dispatch(AuthActions.actionLogout());
          }
        ))
      .subscribe(
        () => { },
        (error) => {
          this.store.dispatch(AuthActions.actionLogout());
          if (error.error.message) {
          } else {
          }
          this.loading = false;
        }); 
  }

  onChangeState(e) {
   // this.translate.addLangs([e, 'klingon']);
    this.localStorageService.setItem("lang", e.value);
    //const userData = LocalStorageService.getItemNew(AUTH_KEY);
    this.translate.setDefaultLang(e.value);
    this.translate.use(e.value);

    window.location.reload();

  }
}
