
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { CityRoutingModule } from './city-routing.module';
import { AddcityComponent } from './addcity/addcity.component';
import {TableModule} from 'primeng/table';
import {ToolbarModule} from 'primeng/toolbar';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {DialogService, DynamicDialogModule, DynamicDialogRef} from 'primeng/dynamicdialog';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {MessageModule} from 'primeng/message';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ToastModule} from 'primeng/toast';
import { MessageService ,ConfirmationService } from 'primeng/api';
import { CityComponent } from './city.component';
import {PanelModule} from 'primeng/panel';
import {DropdownModule} from 'primeng/dropdown';
import {CardModule} from 'primeng/card';
import {TooltipModule} from 'primeng/tooltip';
import { NgxSpinnerModule } from "ngx-spinner";
import { StateCityModel } from 'app/landlords/models/city-state-model';


@NgModule({
  declarations: [
    AddcityComponent,
    CityComponent
  ],
  imports: [
    CommonModule,
    TooltipModule,
    SharedModule,
    CityRoutingModule,
    TableModule,
    ButtonModule,
    ToolbarModule,
    InputTextModule,
    DynamicDialogModule,
    InputTextareaModule,
    MessageModule,
    ConfirmDialogModule,
    ToastModule,
    PanelModule,
    DropdownModule,
    CardModule,
    NgxSpinnerModule,
  ],
  providers:[
    DialogService,
    DynamicDialogRef,
    MessageService,
    StateCityModel,
    ConfirmationService
]
})
export class CityModule { }
