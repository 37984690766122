import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CountryRoutingModule } from './country-routing.module';
import { AddCountryComponent } from './add-country/add-country.component';

import { SharedModule } from '../../shared/shared.module';
import {TableModule} from 'primeng/table';
import {ToolbarModule} from 'primeng/toolbar';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {DialogService, DynamicDialogModule, DynamicDialogRef} from 'primeng/dynamicdialog';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {MessageModule} from 'primeng/message';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ToastModule} from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import {TooltipModule} from 'primeng/tooltip';
import {PanelModule} from 'primeng/panel';
import {CardModule} from 'primeng/card';
import { CountryComponent } from './country.component';

@NgModule({
  declarations: [
    AddCountryComponent,
    CountryComponent
  ],
  imports: [
    CommonModule,
    TooltipModule,
    CountryRoutingModule,
    SharedModule,
    TableModule,
    ButtonModule,
    ToolbarModule,
    InputTextModule,
    DynamicDialogModule,
    InputTextareaModule,
    MessageModule,
    ConfirmDialogModule,
    ToastModule,
    PanelModule,
    CardModule
  ],  providers:[
    DialogService,
    DynamicDialogRef,
    MessageService,
    ConfirmationService
]
})
export class CountryModule { }
