import { NgModule } from '@angular/core';

import { SettingRoutingModule } from './setting-routing.module';
import { SettingComponent } from './setting.component';
import { MaterialModule } from '../shared/material.module';
import { SharedModule } from '../shared/shared.module';
import {SplitterModule} from 'primeng/splitter';
import { CountryComponent } from './country/country.component';


@NgModule({
    imports: [
        SharedModule,
        SettingRoutingModule,
        SplitterModule
    ],
    declarations: [
        SettingComponent
    ]
})

export class SettingModule {}
