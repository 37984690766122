import { Component, OnInit } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { LocalStorageService } from './core/core.module';
import { UserSettingService } from './settings/user/data/user-setting.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[];
    activeUser: any;
    lang: string;
    constructor(public app: AppMainComponent,
        private userService: UserSettingService,public localStorageService:LocalStorageService) {
        this.activeUser = this.userService.getActiveUser();
    }

    ngOnInit() {
        this.lang = this.localStorageService.getItem("lang");
        if (this.activeUser?.userType == "admin") {
            if (this.lang == 'fr') {
                this.model = [
                    { label: 'Tableau de bord', icon: 'pi pi-fw pi-home', routerLink: ['/dashboard'] },
                    {
                        label: 'Propriétaire', icon: 'pi pi-fw pi-users', routerLink: ['/landlords']
                    }, 
                    {
                        label: 'Propriétés', icon: 'pi pi-fw pi-building', routerLink: ['/properties']
                    },
                    {
                        label: 'Locataires', icon: 'pi pi-fw pi-slack', routerLink: ['/tenants']
                    },

                    {
                        label: 'Contrats de location', icon: 'pi pi-fw pi-sitemap', routerLink: ['/leases']
                    },
                    {
                        label: 'Factures', icon: 'pi pi-fw pi-file-import', routerLink: ['/invoices']
                    },
                    {
                        label: 'Paiements', icon: 'pi pi-fw pi-compass', routerLink: ['/payments']
                    },
                    {
                        label: "Contrats d'abonnement", icon: 'pi pi-fw pi-prime', routerLink: ['/Subcription']
                    },
                    {
                        label: 'Les Préavis de depart', icon: 'pi pi-fw pi-file-edit', routerLink: ['/notices']
                    },

                    {
                        label: 'Paramètres', icon: 'pi pi-fw pi-cog', routerLink: ['/settings'],
                        items: [
                            { label: 'réglages généraux ', icon: 'pi pi-fw pi-briefcase', routerLink: ['settings/general-setting'] },
                            { label: 'Types de propriétés', icon: 'pi pi-fw pi-pencil', routerLink: ['/settings/property/property_types'] },
                            { label: 'Type de contrat de location', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['/settings/lease/lease_type'] },
                            { label: 'Type de locataire', icon: 'pi pi-fw pi-user-plus', routerLink: ['settings/tenant/tenant_type'] },
                            { label: 'Pays', icon: 'pi pi-fw pi-flag-fill', routerLink: ['settings/country'] },
                            { label: 'Département', icon: 'pi pi-fw pi-globe', routerLink: ['settings/state'] },
                            { label: 'Ville', icon: 'pi pi-fw pi-map', routerLink: ['settings/city'] },
                            { label: "Relation en cas d'urgence", icon: 'pi pi-fw pi-user', routerLink: ['settings/emergency'] },
                            { label: "Type d'emploi", icon: 'pi pi-fw pi-money-bill', routerLink: ['settings/employment'] },
                            { label: "Pièce d'identité", icon: 'pi pi-fw pi-book', routerLink: ['settings/idproof'] },
                            { label: 'Lien de parenté', icon: 'pi pi-fw pi-user-edit', routerLink: ['settings/kin-relation'] },
                            { label: 'charge supplementaire', icon: 'pi pi-fw pi-calculator', routerLink: ['settings/extracharges1'] }
                            

                        ]
                    }
                ];
            } else if (this.lang == 'en') {
                this.model = [
                    { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/dashboard'] },
                    {
                        label: 'Property Owner', icon: 'pi pi-fw pi-users', routerLink: ['/landlords']
                    },
                    {
                        label: 'Properties', icon: 'pi pi-fw pi-building', routerLink: ['/properties']
                    },
                    {
                        label: 'Tenants', icon: 'pi pi-fw pi-slack', routerLink: ['/tenants']
                    },

                    {
                        label: 'Leases', icon: 'pi pi-fw pi-sitemap', routerLink: ['/leases']
                    },
                    {
                        label: 'Invoices', icon: 'pi pi-fw pi-file-import', routerLink: ['/invoices']
                    },
                    {
                        label: 'Payments', icon: 'pi pi-fw pi-compass', routerLink: ['/payments']
                    },
                    {
                        label: 'Subscription', icon: 'pi pi-fw pi-prime', routerLink: ['/Subcription']
                    },
                    {
                        label: 'Vacate Notices', icon: 'pi pi-fw pi-file-edit', routerLink: ['/notices']
                    },

                    {
                        label: 'Settings', icon: 'pi pi-fw pi-cog', routerLink: ['/settings'],
                        items: [
                            { label: 'General Settings', icon: 'pi pi-fw pi-briefcase', routerLink: ['settings/general-setting'] },
                            { label: 'Property Settings', icon: 'pi pi-fw pi-pencil', routerLink: ['/settings/property/property_types'] },
                            { label: 'Lease Type', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['/settings/lease/lease_type'] },
                            { label: 'Tenant Type', icon: 'pi pi-fw pi-user-plus', routerLink: ['settings/tenant/tenant_type'] },
                            { label: 'Country', icon: 'pi pi-fw pi-flag-fill', routerLink: ['settings/country'] },
                            { label: 'State', icon: 'pi pi-fw pi-globe', routerLink: ['settings/state'] },
                            { label: 'City', icon: 'pi pi-fw pi-map', routerLink: ['settings/city'] },
                            { label: 'Emergency Relation', icon: 'pi pi-fw pi-user', routerLink: ['settings/emergency'] },
                            { label: 'Employment Status', icon: 'pi pi-fw pi-money-bill', routerLink: ['settings/employment'] },
                            { label: 'ID Proof', icon: 'pi pi-fw pi-book', routerLink: ['settings/idproof'] },
                            { label: 'Next Of Kin Relation', icon: 'pi pi-fw pi-user-edit', routerLink: ['settings/kin-relation'] },
                            { label: 'Extra Charges', icon: 'pi pi-fw pi-calculator', routerLink: ['settings/extracharges1'] }
                          

                        ]
                    }
                ];
            }



        } else if (this.activeUser?.userType == 'am-landlord') {

            if(this.lang=='fr'){
                this.model = [
                    { label: 'Tableau de bord', icon: 'pi pi-fw pi-home', routerLink: ['/landlord/dashboard'] },
                    {
                        label: 'Propriétés', icon: 'pi pi-fw pi-building', routerLink: ['/properties']
                    },
                    {
                        label: 'Locataires', icon: 'pi pi-fw pi-slack', routerLink: ['/tenants']
                    },
                    {
                        label: 'Contrats de location', icon: 'pi pi-fw pi-sitemap', routerLink: ['/leases']
                    },
    
                    {
                        label: 'Factures', icon: 'pi pi-fw pi-file-import', routerLink: ['/invoices']
                    },
                    {
                        label: 'Paiements', icon: 'pi pi-fw pi-compass', routerLink: ['/payments']
                    },
    
                    {
                        label: 'Les Préavis de depart', icon: 'pi pi-fw pi-file-edit', routerLink: ['/notices']
                    },
    
                    // {
                    //     label: 'Paramètres', icon: 'pi pi-fw pi-cog', routerLink: ['/settings'],
                    //     items: [
                    //         { label: 'Types de propriétés', icon: 'pi pi-fw pi-pencil', routerLink: ['/settings/property/property_types'] },
                    //         { label: 'Type de contrat de location', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['/settings/lease/lease_type'] },
                    //         { label: 'Type de locataire', icon: 'pi pi-fw pi-user-plus', routerLink: ['settings/tenant/tenant_type'] },
                    //         { label: 'Pays', icon: 'pi pi-fw pi-flag-fill', routerLink: ['settings/country'] },
                    //         { label: 'Département', icon: 'pi pi-fw pi-globe', routerLink: ['settings/state'] },
                    //         { label: 'Ville', icon: 'pi pi-fw pi-map', routerLink: ['settings/city'] },
                    //         { label: "Relation en cas d'urgence", icon: 'pi pi-fw pi-user', routerLink: ['settings/emergency'] },
                    //         { label: "Type d'emploi", icon: 'pi pi-fw pi-money-bill', routerLink: ['settings/employment'] },
                    //         { label: "Pièce d'identité", icon: 'pi pi-fw pi-book', routerLink: ['settings/idproof'] },
                    //         { label: 'Lien de parenté', icon: 'pi pi-fw pi-user-edit', routerLink: ['settings/kin-relation'] },
                    //         { label: 'charge supplementaire', icon: 'pi pi-fw pi-calculator', routerLink: ['settings/extracharges1'] },
    
                    //     ]
                    // }
                ];
            }else if(this.lang=='en'){

                this.model = [
                    { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/landlord/dashboard'] },
                    {
                        label: 'Properties', icon: 'pi pi-fw pi-building', routerLink: ['/properties']
                    },
                    {
                        label: 'Tenants', icon: 'pi pi-fw pi-slack', routerLink: ['/tenants']
                    },
                    {
                        label: 'Leases', icon: 'pi pi-fw pi-sitemap', routerLink: ['/leases']
                    },
    
                    {
                        label: 'Invoices', icon: 'pi pi-fw pi-file-import', routerLink: ['/invoices']
                    },
                    {
                        label: 'Payments', icon: 'pi pi-fw pi-compass', routerLink: ['/payments']
                    },
    
                    {
                        label: 'Vacate Notices', icon: 'pi pi-fw pi-file-edit', routerLink: ['/notices']
                    },
    
                    // {
                    //     label: 'Settings', icon: 'pi pi-fw pi-cog', routerLink: ['/settings'],
                    //     items: [
                    //         { label: 'Property Settings', icon: 'pi pi-fw pi-pencil', routerLink: ['/settings/property/property_types'] },
                    //         { label: 'Lease Type', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['/settings/lease/lease_type'] },
                    //         { label: 'Tenant Type', icon: 'pi pi-fw pi-user-plus', routerLink: ['settings/tenant/tenant_type'] },
                    //         { label: 'Country', icon: 'pi pi-fw pi-flag-fill', routerLink: ['settings/country'] },
                    //         { label: 'State', icon: 'pi pi-fw pi-globe', routerLink: ['settings/state'] },
                    //         { label: 'City', icon: 'pi pi-fw pi-map', routerLink: ['settings/city'] },
                    //         { label: 'Emergency Relation', icon: 'pi pi-fw pi-user', routerLink: ['settings/emergency'] },
                    //         { label: 'Employment Status', icon: 'pi pi-fw pi-money-bill', routerLink: ['settings/employment'] },
                    //         { label: 'ID Proof', icon: 'pi pi-fw pi-book', routerLink: ['settings/idproof'] },
                    //         { label: 'Next Of Kin Relation', icon: 'pi pi-fw pi-user-edit', routerLink: ['settings/kin-relation'] },
                    //         { label: 'Extra Charges', icon: 'pi pi-fw pi-calculator', routerLink: ['settings/extracharges1'] },
    
                    //     ]
                    // }
                ];
            }
          

        }
    }


    public navigate(selectedMenuItem) {

        console.log("selectedMenuItem", selectedMenuItem)
        // if (selectedMenuItem.label === "Admin") {
        //     this.activeItem = selectedMenuItem;
        //     this.activeItem.routerLink[0] = ' ';
        // }
        // else
        //     if (selectedMenuItem.label !== "Admin") {
        let menuItem: any[] = ['/tenants'];

        this.model.forEach(function (item) {
            if (item.label === "Admin") {
                //item.routerLinkActiveOptions = "{exact: true}"
                delete item.routerLink;
                //item.routerLink[0] = menuItem;
            }
        });
        //     }
    }

    onMenuClick(event) {
        this.app.onMenuClick(event);
    }
}
