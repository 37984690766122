import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CityModel } from '../model/city-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseService } from '../../../shared/base-service';

@Injectable({ providedIn: 'root' })
export class CityService extends BaseService<CityModel> {
    private selectedCitySource = new BehaviorSubject<CityModel | null>(null);
    selectedCitySourceChanges$ = this.selectedCitySource.asObservable();

    private  localHttpClient: HttpClient;
    constructor(httpClient: HttpClient) {
        super( httpClient, 'city');
        this.localHttpClient = httpClient;
    }

    changeSelectedTenantType(selectedCity: CityModel | null ): void {
        this.selectedCitySource.next(selectedCity);
    }
}

