import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NotificationService } from '../../shared/notification.service';
import { UserSettingService } from '../../settings/user/data/user-setting.service';
import { ResetPasswordModel } from './reset-password.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
@Component({ templateUrl: 'reset-password.component.html', providers: [MessageService] })
export class ResetPasswordComponent implements OnInit {

    form: UntypedFormGroup;
    formErrors: any;
    loader = false;

    resetModel: ResetPasswordModel;
    email: any;
    constructor(private fb: UntypedFormBuilder,public translate:TranslateService ,private userService: UserSettingService, public route: ActivatedRoute,
        private notification: NotificationService, private router: Router, public messageService: MessageService) {

        this.route.queryParams.subscribe(res => {
            console.log("res", res.email)
            if (res) {
                this.email = res.email;
            }

        });

        let emailRegex =
            '^[a-zA-Z0-9_+&*-]+(?:\\.' +
            '[a-zA-Z0-9_+&*-]+)*@' +
            '(?:[a-zA-Z0-9-]+\\.)+[a-z' +
            'A-Z]{2,8}' + '[\ ]{0,50}$';
        this.form = this.fb.group({
            email: ['', [Validators.required, Validators.pattern(emailRegex),
            Validators.minLength(3)]],
            token: ['', [Validators.required,
            Validators.minLength(3)]],
            password: ['', [Validators.required,
            Validators.pattern(
                '^(?=.*?[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])(?=.*?[0-9]).{6,14}$'
            )]],
            password_confirmation: ['', [Validators.required,
            this.equalto('password'),]],
        });
    }


    equalto(field_name): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            let input = control.value;
            console.log("input", input);

            let isValid = control.root.value[field_name] == input;
            if (!isValid) return { equalTo: { isValid } };
            else return null;
        };
    }
    /**
     *
     */
    ngOnInit(): void { }

    /**
     *
     */
    resetPassword() {
        this.form.get('email').markAsTouched();
        this.form.get('token').markAsTouched();
        this.form.get('password').markAsTouched();
        this.form.get('password_confirmation').markAsTouched();
        if (this.form.valid) {

            const body = Object.assign({}, this.resetModel, this.form.value);

            console.log(body);

            this.loader = true;
            this.userService.resetPassword(body)
                .subscribe((data) => {
                    this.loader = false;
                    // navigate to login
                    console.log("sasdas", data)
                    if (data.status_code == '400') {
                        this.translate.get('alert').subscribe(async (val) => {
                            this.messageService.add({ severity: 'error', summary: val.Error, detail: val.invalidtoken });
                        });

           
                    } else {
                        this.translate.get('alert').subscribe(async (val) => {
                            this.messageService.add({ severity: 'success', summary: val.Success, detail: val.resetsuccess });
                        });

                        setTimeout(() => {
                            this.router.navigate(['login']);
                        }, 2000);
                    }

                },
                    (error) => {
                        this.loader = false;

                        if (error.message) {

                            return;
                        }
                        // An array of all form errors as returned by server
                        this.formErrors = error;

                        if (this.formErrors) {
                            // loop through from fields, If has an error, mark as invalid so mat-error can show
                            for (const prop in this.formErrors) {
                                if (this.form) {
                                    this.form.controls[prop].setErrors({ incorrect: true });
                                }
                            }
                        }
                    });
        }
    }
}
