import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { LocalStorageService } from './core/core.module';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  loading = true;
  menuMode = 'static';

  inputStyle = 'outlined';

  ripple: boolean;

  theme = 'teal-yellow';

  lightMenu = true;
  constructor(translate: TranslateService, private router: Router, public localStorageService: LocalStorageService) {


    const language = this.localStorageService.getItem("lang");

    console.log("language", language)

    if (language == null) {
      translate.addLangs(['en', 'klingon']);
      translate.setDefaultLang('en');
      translate.use('en');
      this.localStorageService.setItem("lang",'en');
    }else{
      translate.setDefaultLang(language);
      translate.use(language);
    }

    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loading = true;
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        this.loading = false;
      }
    });
  }

}
