import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { BaseService } from '../../../shared/base-service';
import { CountryModel } from '../model/country.model';

@Injectable({ providedIn: 'root' })
export class CountryService extends BaseService<CountryModel> {
    private selectedEmergencySource = new BehaviorSubject<CountryModel | null>(null);
    selectedEmergencySourceChanges$ = this.selectedEmergencySource.asObservable();

    private  localHttpClient: HttpClient;
    constructor(httpClient: HttpClient) {
        super( httpClient, 'country');
        this.localHttpClient = httpClient;
    }

    changeSelectedTenantType(selectedEmergency: CountryModel | null ): void {
        this.selectedEmergencySource.next(selectedEmergency);
    }
}