import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddcityComponent } from './addcity/addcity.component';
import { CityComponent } from './city.component';



export const ROUTES: Routes = [
  {
    path: '',
    component: CityComponent,
  }
];



@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule]
})
export class CityRoutingModule { }
