import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { DynamicDialogRef, DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { LazyLoadEvent } from 'primeng/api';

import { TranslateService } from '@ngx-translate/core';
import { CountryModel } from '../model/country.model';
import { CountryService } from '../data/country.service';

@Component({
    selector: 'app-add-country',
    templateUrl: './add-country.component.html',
    styleUrls: []
})
export class AddCountryComponent implements OnInit {

    form: UntypedFormGroup;

    formErrors: any;

    private errorInForm = new BehaviorSubject<boolean>(false);
    formError$ = this.errorInForm.asObservable();

    loader = false;

    formGroup: UntypedFormGroup;

    isAdd: boolean;
    countryModel: CountryModel;




    constructor(private messageService: MessageService, private countryService: CountryService, private primengConfig: PrimeNGConfig, private fb: UntypedFormBuilder, public config: DynamicDialogConfig, public ref: DynamicDialogRef, public translate: TranslateService) {

    }
    ngOnInit() {
        this.primengConfig.ripple = true;
        console.log(this.config.data);

        if (this.config.data.isAdd == true) {
            this.form = this.fb.group({
                country_name: ['', [Validators.required]],
                country_display_name: ['', [Validators.required]],
                country_description: [''],
                country_display_name_fr: ['', [Validators.required]],
                country_code: ['', [Validators.required]],
            });
        } else if (this.config.data.isAdd == false) {
            this.form = this.fb.group({
                country_name: [this.config.data.country_name],
                country_display_name: [this.config.data.country_display_name, [Validators.required]],
                country_display_name_fr: [this.config.data.country_display_name_fr, [Validators.required]],
                country_description: [this.config.data.country_description],
                id: [this.config.data.id],
                country_code: [this.config.data.country_code],
            });
        }
    }

    myFunction(event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        console.log("event.data", event);
        if (event.key == "-") {
            event.preventDefault();
            return false;
        } else if ((charCode < 48 || charCode > 57)) {
            event.preventDefault();
            return false;
        }
    }

    /**
     * Create member
     */
    create() {
        let value = this.form.value.country_display_name + this.form.value.country_display_name_fr;
        this.form.patchValue({ country_name: value });

        this.form.get('country_display_name').markAsTouched();
        this.form.get('country_code').markAsTouched();
        this.form.get('country_display_name_fr').markAsTouched();
        if (this.form.valid) {

            let signPlus = "+" + this.form.value.country_code;
            this.form.patchValue({ country_code: signPlus });

            this.errorInForm.next(false);

            const body = Object.assign({}, this.countryModel, this.form.value);

            this.loader = true;

            this.countryService.create(body).subscribe((data) => {
                this.onSaveComplete();

                this.translate.get('alert').subscribe(async (val) => {
                    this.messageService.add({ severity: 'success', summary: val.Success, detail: val.country_created });
                });
            },
                (error) => {
                    this.loader = false;
                    this.errorInForm.next(true);
                    this.formErrors = error;
                    if (this.formErrors) {
                        for (const prop in this.formErrors) {
                            if (this.form) {
                                this.form.controls[prop]?.markAsTouched();
                                this.form.controls[prop]?.setErrors({ incorrect: true });
                            }
                        }
                    }
                    if (error.country_display_name[0]) {
                        this.translate.get('alert').subscribe(async (val) => {
                            this.messageService.add({ severity: 'error', summary: val.Error, detail: val.country_display_name });
                        });
                    }
                    if (error.country_display_name_fr[0]) {
                        this.translate.get('alert').subscribe(async (val) => {
                            this.messageService.add({ severity: 'error', summary: val.Error, detail: val.country_display_name_fr });
                        });
                    }
                });
        }
    }

    /**
     *
     */
    update() {
        let value = this.form.value.country_display_name + this.form.value.country_display_name_fr;
        this.form.patchValue({ country_name: value });
        this.form.get('country_display_name').markAsTouched();
        this.form.get('country_code').markAsTouched();
        this.form.get('country_display_name_fr').markAsTouched();
        if (this.form.valid) {
            let letter = this.form.value.country_code.charAt(0);
            if(letter!="+"){
                let signPlus = "+" + this.form.value.country_code;
                this.form.patchValue({ country_code: signPlus });
            }
            const body = Object.assign({}, this.countryModel, this.form.value);

            this.loader = true;
            this.errorInForm.next(false);

            this.countryService.update(body).subscribe((data) => {
                this.loader = false;

                this.ref.close('yes');

                // notify success

                this.translate.get('alert').subscribe(async (val) => {
                    this.messageService.add({ severity: 'success', summary: val.Success, detail: val.country_updated });
                });

            },
                (error) => {
                    this.loader = false;
                    this.errorInForm.next(true);
                    this.formErrors = error;
                    if (this.formErrors) {
                        for (const prop in this.formErrors) {
                            if (this.form) {
                                this.form.controls[prop]?.markAsTouched();
                                this.form.controls[prop]?.setErrors({ incorrect: true });
                            }
                        }
                    }
                    if (error.country_display_name[0]) {
                        this.translate.get('alert').subscribe(async (val) => {
                            this.messageService.add({ severity: 'error', summary: val.Error, detail: val.country_display_name });
                        });
                    }
                    if (error.country_display_name_fr[0]) {
                        this.translate.get('alert').subscribe(async (val) => {
                            this.messageService.add({ severity: 'error', summary: val.Error, detail: val.country_display_name_fr });
                        });
                    }
                });
        }
    }

    /**
     * Create or Update Data
     */
    createOrUpdate() {
        this.isAdd ? this.create() : this.update();
    }

    close() {
        this.ref.close();
    }

    /**
     *
     */
    public onSaveComplete(): void {
        this.loader = false;
        this.form.reset();
        this.ref.close('yes');
    }

}


