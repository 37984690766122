import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { fromEvent, merge, Observable } from 'rxjs';
import { ConfirmationDialogComponent } from '../shared/delete/confirmation-dialog-component';
import { AddPropertyComponent } from './add/add-property.component';
import { PropertyModel } from './models/property-model';
import { PropertyDataSource } from './data/property-data.source';
import { NotificationService } from '../shared/notification.service';
import { PropertyService } from './data/property.service';
import { LandlordService } from '../landlords/data/landlord.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { UserSettingService } from '../settings/user/data/user-setting.service';
import { TenantService } from '../tenants/data/tenant.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { PropertyStatusService } from './data/property_status.service';
import * as moment from 'moment';
import { LocalStorageService } from 'app/core/core.module';

@Component({
    selector: 'robi-properties',
    templateUrl: './property.component.html',
    styleUrls: ['./property.component.scss']
})
export class PropertyComponent implements OnInit, AfterViewInit {

    displayedColumns = [
        'property_code',
        'property_name',
        'location',
        'total_units',
        'actions'
    ];

    loader = false;



    isLandlord = false;
    landlordID: string;

    // Search field
    @ViewChild('search') search: ElementRef;



    // Pagination
    length: number;
    pageIndex = 0;
    pageSizeOptions: number[] = [5, 10, 25, 50, 100];
    meta: any;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    isAdmin$: Observable<boolean>;
    activeUser: any;
    Id: any = [];
    Property_all_data: any = []
    selectAll: boolean = false;
    first = 0;
    page: any = 0;
    rows = 5;
    search_data: any;
    alldata: any = [];
    loading: boolean;

    PropertyModel: PropertyModel;


    totalRecords: any = [];
    selectedData: PropertyModel[];

    end_date: any;
    diffInDays: number;

    constructor(private userService: UserSettingService,
        private landlordService: LandlordService,
        private propertyService: PropertyService,
        private notification: NotificationService,
        private authenticationService: AuthenticationService,
        public confirmationService: ConfirmationService,
        public translate: TranslateService,
        public Property_status_service: PropertyStatusService,
        public messageService: MessageService,
        public localStorageService:LocalStorageService) {
        this.activeUser = this.userService.getActiveUser();
        this.isAdmin$ = this.authenticationService.isAdmin();
        this.isLandlord = this.landlordService.isLandlord();
        this.landlordID = this.landlordService.getLoggedInLandlordID();

        this.end_date = this.localStorageService.getItem("end_date");
        let current_date = moment(new Date()).format('YYYY-MM-DD')
        const date1 = moment(this.end_date);
        const date2 = moment(current_date);
        var ms = moment(date1, "YYYY-MM-DD").diff(moment(date2));
        var d = moment.duration(ms);
        this.diffInDays = d.asDays();
        console.log("diffInDays", this.diffInDays)
    }

    /**
     * Initialize data lead
     * Set pagination data values
     * Initial data load
     */
    ngOnInit() {

    }

    load_properties_data(e?) {
        this.selectedData = null;
        this.Id = [];

        if (e.first == 0) {
            this.page = 1
        } else {
            this.page = (e.first / e.rows) + 1;
        }
        console.log("this.activeUser?.userType", this.activeUser?.userType)
        if (this.activeUser?.userType == "admin") {
            this.loading = true;
            this.propertyService.getAll(e.globalFilter == null ? '' : e.globalFilter, this.page, e.rows, e.sortField == undefined ? 'updated_at' : e.sortField, e.sortOrder == 1 ? 'desc' : 'asc', '', '').subscribe((res: any) => {
                this.Property_all_data = res.data
                this.Property_all_data.map(item => {
                    if (item.status == 'true') {
                        item.status_new = true;
                    } else {
                        item.status_new = false;
                    }
                })
                this.alldata = res;
                this.totalRecords = this.alldata.meta?.total;
                this.loading = false;
            }, error => {
                this.loading = false;
            });
        } else if (this.activeUser?.userType == 'am-landlord') {
            this.loading = true;
            this.landlordService.getNestedNew(this.activeUser?.userID + "/properties", e.globalFilter == null ? '' : e.globalFilter, this.page, e.rows, e.sortField == undefined ? 'updated_at' : e.sortField, e.sortOrder == 1 ? 'desc' : 'asc', '', '').subscribe((res: any) => {
                this.Property_all_data = res.data
                this.Property_all_data.map(item => {
                    if (item.status == 'true') {
                        item.status_new = true;
                    } else {
                        item.status_new = false;
                    }
                })
                this.alldata = res;
                this.totalRecords = this.alldata.meta?.total;
                this.loading = false;
            }, error => {
                this.loading = false;
            });
        }

    }

    /**
     * Handle search and pagination
     */
    ngAfterViewInit() {

    }

    /**
     * Empty search box
     */


    clear(table: Table) {
        table.clear();
        this.search_data = '';
        this.rows = 5;
        this.page = 0;
    }

    /**
     * Fetch data from data lead
     */
    loadData() {
        this.selectedData = null;
        this.Id = [];
        if (this.activeUser?.userType == "admin") {
            this.loading = true;
            this.propertyService.getAll('', 1, this.rows, 'updated_at', 'desc', '', '').subscribe((res: any) => {
                this.Property_all_data = res.data
                this.Property_all_data.map(item => {
                    if (item.status == 'true') {
                        item.status_new = true;
                    } else {
                        item.status_new = false;
                    }
                })
                //console.log("res", res);
                this.alldata = res;
                this.totalRecords = this.alldata.meta?.total;
                this.loading = false;
            }, error => {
                this.loading = false;
                //console.log(JSON.stringify(error));
                //console.log("Oooops!")
            });
        } else if (this.activeUser?.userType == 'am-landlord') {
            this.loading = true;
            this.landlordService.getNestedNew(this.activeUser?.userID + "/properties",'', 1, this.rows, 'updated_at', 'desc', '', '').subscribe((res: any) => {
                this.Property_all_data = res.data
                this.Property_all_data.map(item => {
                    if (item.status == 'true') {
                        item.status_new = true;
                    } else {
                        item.status_new = false;
                    }
                })
                //console.log("res", res);
                this.alldata = res;
                this.totalRecords = this.alldata.meta?.total;
                this.loading = false;
            }, error => {
                this.loading = false;
                //console.log(JSON.stringify(error));
                //console.log("Oooops!")
            });
        }
    }

    /**
   * Open Edit form
   * @param Property
   */

    openConfirmationDialog(property_nameData, index) {
        this.translate.get('alert').subscribe(async (val) => {
            this.confirmationService.confirm({
                message: val.message + " " + property_nameData.property_name + '?',
                header: val.header,
                icon: 'pi pi-exclamation-triangle',
                key: 'confirmWeightTest',
                accept: () => {
                    this.delete(property_nameData, index);
                }
            });
        });

    }

    /**
     * Remove resource from db
     * @param property
     */
    delete(property: PropertyModel, index) {
        this.selectedData = null;
        this.Id = [];
        this.loader = true;
        this.propertyService.delete(property)
            .subscribe((data) => {
                this.loader = false;
                this.Property_all_data.splice(index, 1);
                this.translate.get('alert').subscribe(async (val) => {
                this.messageService.add({ severity: 'success', summary: val.Success, detail: val.Property_delete });
                });
            },
                (error) => {
                    this.loader = false;
                    if (error.error['message']=="Property has pending invoices") {
                        this.translate.get('alert').subscribe(async (val) => {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: val.PropertyInvoices });
                        });
                    } else {
                        this.messageService.add({ severity: 'error', summary: "Error", detail: "Delete Error !!" });

                    }
                });
    }

    /**
     * @param property
     */
    onSelected(property: PropertyModel): void {
        this.propertyService.changeSelectedProperty(property);
    }

    private delete1(landlord: PropertyModel) {
        this.selectedData = null;
        this.Id = [];
        //console.log("landlord", landlord.status)
        let status;
        if (landlord.status == 'true') {
            status = 'false'
        } else {
            status = 'true'
        }
        this.loader = true;

        let body1 = {
            id: landlord.id,
            status: status
        }
        const body = Object.assign({}, this.PropertyModel, body1);


        this.Property_status_service.status_change(body)
            .subscribe((data) => {
                this.loader = false;
                //this.loadData();
                this.translate.get('alert').subscribe(async (val) => {
                    this.messageService.add({ severity: 'success', summary: val.Success, detail: val.status_landlord });
                });
            },
                (error) => {
                    this.loader = false;
                    if (error.error['message']) {
                        this.messageService.add({ severity: 'error', summary: "Error", detail: error.error['message'] });

                    } else {
                        this.notification.showNotification('danger', 'Delete Error !! ');
                    }
                });
    }
    onSelectionChange(value = []) {
        //console.log("value", value)
        this.selectAll = value.length === this.totalRecords;
        this.selectedData = value;
    }

    onSelectAllChange(event) {
        //console.log("event", event)
        const checked = event.checked;

        if (checked) {

            this.selectedData = this.Property_all_data;
            this.selectAll = true;

        }
        else {
            this.selectedData = [];
            this.selectAll = false;
        }
    }

    deleteSelectedProducts() {
        this.translate.get('alert').subscribe(async (val) => {
            this.confirmationService.confirm({
                message: val.message_new + " " + val.Properties + '?',
                header: val.header,
                icon: 'pi pi-exclamation-triangle',
                key: 'confirmWeightTest',
                accept: () => {
                    this.AllSelectedDeleted();
                }
            });

        });
    }

    AllSelectedDeleted() {
        let myItem = this.selectedData.map((item) => {
            this.Id.push({ id: item.id });
        });
        let body = {
            ids_data: this.Id
        }
        this.propertyService.deleteSelectedData(body, "/all_properties_destroy").subscribe(res => {
            //console.log(res);
            this.loader = false;
            this.loadData();
            this.selectedData = null;
            this.Id = [];
            this.translate.get('alert').subscribe(async (val) => {
                this.messageService.add({ severity: 'success', summary: val.Success, detail: val.Property_delete });
            });
        },
            (error) => {
                this.selectedData = null;
                this.Id = [];
                this.loader = false;
                if (error.error['message']=="Property has pending invoices") {
                    this.translate.get('alert').subscribe(async (val) => {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: val.PropertyInvoices });
                    });
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Delete Error !! ' });
                }
            });
    }


}
