import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { BaseService } from '../../../shared/base-service';
import { StateModel } from '../model/state-model';

@Injectable({ providedIn: 'root' })
export class StateService extends BaseService<StateModel> {
    private selectedStateSource = new BehaviorSubject<StateModel | null>(null);
    selectedStateSourceChanges$ = this.selectedStateSource.asObservable();

    private  localHttpClient: HttpClient;
    constructor(httpClient: HttpClient) {
        super( httpClient, 'state');
        this.localHttpClient = httpClient;
    }

    changeSelectedTenantType(selectedState: StateModel | null ): void {
        this.selectedStateSource.next(selectedState);
    }
}

