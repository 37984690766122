import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PropertyModel } from '../models/property-model';
import { BaseService } from '../../shared/base-service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PropertyStatusService extends BaseService<PropertyModel> {
    private selectedPropertySource = new BehaviorSubject<PropertyModel | null>(null);
    selectedPropertyChanges$ = this.selectedPropertySource.asObservable();

    private  localHttpClient: HttpClient;
    constructor(httpClient: HttpClient) {
        super( httpClient, 'property_update_status');
        this.localHttpClient = httpClient;
    }
}